<template>
<section class="poma_m">
   <div class="banner">
      <p :class="$i18n.locale == 'en' ? 'wrap' : 'nowrap'">{{ $t("PC.Product.POMA.sgfs7b") }}</p>
      <a :href="pomaUrl">
         <span>{{ $t("PC.Product.POMA.rms9wl") }}</span>
      </a>
      <img class="bg" src="../../assets/zh/images-mobile//suanfas_banner@2x(1).png" alt="" />
   </div>
   <div class="big_contain">
      <div class="box_1">
         <div class="setmeal">
            <span>{{ $t('PC.Product.POMA.Price.biao-zhun-ban-basic') }}</span>
         </div>
         <div class="limit">
            <span>{{ $t('PC.Product.POMA.Price.cpu-he-shu-1') }}：1 <i class="iconfont iconxuanzhong_gongzuotai"></i></span>
            <span>{{ $t('PC.Product.POMA.Price.cun-chu-kong-jian-3g') }} ：3G<i class="iconfont iconxuanzhong_gongzuotai"></i></span>
            <span>{{ $t('PC.Product.POMA.Price.tong-shi-xun-lian-ren-wu-shu-1') }}：1 <i class="iconfont iconxuanzhong_gongzuotai"></i></span>
            <span>{{ $t('PC.Product.POMA.Price.shou-hou-fu-wu') }} <i class="iconfont iconxuanzhong_gongzuotai"></i></span>
            <span>GPU <i class="iconfont iconshanchu_bianqian"></i></span>
         </div>
         <div class="set_price">
            <span><i>￥</i>89<i>{{ $t('PC.Product.POMA.Price.yue') }}</i></span>
         </div>
      </div>
      <div class="box_2">
         <div class="setmeal">
            <span>{{ $t('PC.Product.POMA.Price.zuan-shi-ban-diamond') }}</span>
         </div>
         <div class="limit">
            <span>{{ $t('PC.Product.POMA.Price.cpu-he-shu-1') }}：5 <i class="iconfont iconxuanzhong_gongzuotai"></i></span>
            <span>{{ $t('PC.Product.POMA.Price.cun-chu-kong-jian-3g') }}：7G <i class="iconfont iconxuanzhong_gongzuotai"></i></span>
            <span>{{ $t('PC.Product.POMA.Price.tong-shi-xun-lian-ren-wu-shu-1') }}：4 <i class="iconfont iconxuanzhong_gongzuotai"></i></span>
            <span>{{ $t('PC.Product.POMA.Price.shou-hou-fu-wu') }} <i class="iconfont iconxuanzhong_gongzuotai"></i></span>
            <span>GPU <i class="iconfont iconshanchu_bianqian"></i></span>
         </div>
         <div class="set_price">
            <span><i>￥</i>158<i>{{ $t('PC.Product.POMA.Price.yue') }}</i></span>
         </div>
      </div>
      <div class="box_3">
         <div class="setmeal">
            <span>{{ $t('PC.Product.POMA.Price.zhi-zun-ban-premium') }}</span>
         </div>
         <div class="limit">
            <span>{{ $t('PC.Product.POMA.Price.cpu-he-shu-1') }}：7 <i class="iconfont iconxuanzhong_gongzuotai"></i></span>
            <span>{{ $t('PC.Product.POMA.Price.cun-chu-kong-jian-3g') }}：15G <i class="iconfont iconxuanzhong_gongzuotai"></i></span>
            <span>{{ $t('PC.Product.POMA.Price.tong-shi-xun-lian-ren-wu-shu-1') }}：6 <i class="iconfont iconxuanzhong_gongzuotai"></i></span>
            <span>{{ $t('PC.Product.POMA.Price.shou-hou-fu-wu') }} <i class="iconfont iconxuanzhong_gongzuotai"></i></span>
            <span>GPU <i class="iconfont iconxuanzhong_gongzuotai"></i></span>
         </div>
         <div class="set_price">
            <span><i>￥</i>365<i>{{ $t('PC.Product.POMA.Price.yue') }}</i></span>
         </div>
         <!-- <div class="discount">
            <span>年付 8 折</span>
         </div> -->
      </div>
   </div>
   <FooterB></FooterB>
</section>
</template>

<script>
import FooterB from "./components/FooterB";
export default {
   components: {
      FooterB,
   },
   data() {
      return {
         pomaUrl: Global.POMA_URL,
      };
   },
};
</script>

<style lang="scss" scoped>
.poma_m {
   margin-top: 48px;

   .banner {
      position: relative;

      p {
         font-size: 14px;
         font-family:   PingFangSC-Semibold, PingFang SC;
         font-weight: 600;
         color: #fff;
         line-height: 20px;
         position: absolute;
         left: 30px;
         top: 42px;
         z-index: 2;
      }

      .wrap {
         width: 190px;
         top: 22px;
      }

      a {
         white-space: nowrap;
         position: absolute;
         top: 74px;
         left: 30px;
         width: 60px;
         height: 19px;
         border: 1px solid #fff;
         border-radius: 1px;

         span {
            color: #fff;
            display: inline-block;
            width: 100%;
            font-family:   PingFangSC-Semibold, PingFang SC;
            text-align: center;
            line-height: 18px;
            vertical-align: top;
            font-size: 8px;
            transform: scale(0.7);
            transform-origin: center center;
         }
      }

      .price {
         position: absolute;
         top: 74px;
         left: 103px;
         background: transparent;
         border: 1px solid #ffffff;
         border-radius: 1px;
         width: 60px;
         height: 19px;

         span {
            color: #fff;
            display: inline-block;
            width: 100%;
            font-family:   PingFangSC-Semibold, PingFang SC;
            text-align: center;
            line-height: 18px;
            vertical-align: top;
            font-size: 8px;
            transform: scale(0.7);
            transform-origin: center center;
         }
      }

      img.bg {
         width: 100%;
         height: 150px;
      }
   }

   .big_contain {
      box-sizing: border-box;
      padding: 16px 20px;

      &>div {
         width: 335px;
         background: #ffffff;
         border-radius: 5px;
         border: 1px solid #e1e1e1;
         margin-bottom: 16px;

         div.setmeal {
            height: 53px;
            background-image: url("../../assets/zh/images-mobile//dingjia_img1@2x.png");
            background-size: contain;
            background-repeat: no-repeat;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
               font-size: 16px;
               color: #fff;
               line-height: 22px;
            }
         }

         div.limit {
            border-bottom: 1px solid #e1e1e1;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 16px 20px;

            span {
               width: 133px;
               display: flex;
               justify-content: space-between;
            }
         }

         div.set_price {
            font-size: 30px;
            font-weight: 500;
            color: #333;
            line-height: 42px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 16px 0;

            i {
               font-size: 10px;
               font-family:   PingFangSC-Regular, PingFang SC;
               color: #212121;
               line-height: 14px;
               // transform-origin: center;
               // transform: scale(.8);
            }
         }
      }

      div.box_2 {
         div.setmeal {
            background-image: url("../../assets/zh/images-mobile//dingjia_img2@2x.png");

            span {
               color: #875b06;
            }
         }
      }

      div.box_3 {
         position: relative;
         overflow: hidden;

         div.setmeal {
            background-image: url("../../assets/zh/images-mobile//dingjia_img3@2x.png");

            span {
               color: #836029;
            }
         }

         .discount {
            width: 94px;
            height: 15px;
            background-color: #fc7750;
            box-shadow: 0px 2px 6px 0px #c7ab8a;

            transform: rotate(-30deg);
            position: absolute;
            top: 0;
            left: -20px;

            span {
               color: #fff;
               transform-origin: center;
               transform: scale(0.7);
               height: 15px;
               width: 100%;
               display: inline-block;
               vertical-align: top;
               line-height: 15px;
               font-size: 12px;
               text-align: left;
               padding-left: 9px;
            }
         }
      }
   }
}
</style>
